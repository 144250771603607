<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>

<script setup>
import { onMounted } from 'vue-demi'
 onMounted(()=>{
    document.body.style.setProperty('--el-color-primary', '#5760ff');
 })
</script>
<style lang="less">
  html,
  body {
    width: 100%;
    height: 100%;
    min-width: 1400px;
    min-height: 768px;
    color: #666A6D;
    font-size: 62.5%;
    background: #F5F6FA;
  }
  #app {
    position: relative;
    width: 100%;
    /* min-width: 1400px; */
    // height: 100%;
    /* min-height: 1000px; */
  }
  
  .el-message__icon{
    width: 3em !important;
    height: 3em !important;
  svg{
    width: 3em !important;
    height: 3em !important;
  }
  }
</style>
