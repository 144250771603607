import axios from "axios"
import store from '@/store'
import { ElMessage } from 'element-plus'
import router from "@/router"
function api(url, data) {
    return new Promise((res, rej) => {
        const httpService = axios.create({
            baseURL: process.env.VUE_APP_URL,
            timeout: 10000,
            method: "POST",
            //头部信息配置
            headers: {
                'Content-Type':"application/json",
                'device-type':"web",
                "token":store.state.token || ""
            }
        })
        //请求发出前拦截操作
        httpService.interceptors.request.use(config => {
            config.url = url
            config.data = data
            return config
        }, err => {
            Promise.reject("request拦截出错")
            console.log(err);
        })
        //响应拦截，看具体状态来操作
        httpService.interceptors.response.use(response => {
            //请求成功
            if (response.data.code === 200) {
                res(response.data)
                return response
            }else if(response.data.code === 10010){
                ElMessage.error('登录超时')

                router.push("/")
            }
            else{
                ElMessage.error(response.data.msg)
               
                rej(response.data.msg)
            }
        },error=>{  
            ElMessage.error(error.response.data.msg)
        })
        httpService({
            url,
            data
        })

    })

}
export default api