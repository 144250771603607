export default {
    getToken(state) {
        return state.token
    },
    getTags(state) {
        return state.tags
    },
    getTagActive(state) {
        return state.tagActive
    },
    getBreadcrumbs(state) {
        return state.breadcrumbs
    },
    getBreadcrumbs(state) {
        return state.kind
    }
}