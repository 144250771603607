function deepClone (target, hash = new WeakMap()) { 
    if (target === null) return target 
    if (target instanceof Date) return new Date(target) 
    if (target instanceof RegExp) return new RegExp(target) 
    if (target instanceof HTMLElement) return target 
  
    if (typeof target !== 'object') return target 

    if (hash.get(target)) return hash.get(target) 
    const cloneTarget = new target.constructor() 
    hash.set(target, cloneTarget) 
  
    Reflect.ownKeys(target).forEach(key => { 
      cloneTarget[key] = deepClone(target[key], hash) // 
    })
    return cloneTarget 
  }
export default deepClone