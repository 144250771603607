export default {
    setToken: ({ commit }, log) => {
        commit('setToken', log);
    },
    setTags: ({ commit }, log) => {
        commit('setTags', log);
    },
    setTagActive: ({ commit }, log) => {
        commit('setTagActive', log);
    },
    setBreadcrumbs: ({ commit }, log) => {
        commit('setBreadcrumbs', log);
    },
    setKind: ({ commit }, log) => {
        commit('setKind', log);
    },
}