import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import 'normalize.css/normalize.css'
import router from "@/permission"
import store from "@/store/index"
import downFile from "@/utils/downloadFile"
import SvgIcon from '@/components/SvgIcon/index.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import api from "@/utils/api.js"
import deepClone from "@/utils/deepClone.js"
import "@/assets/style/global.css"
const req = require.context('@/icons/svg', false, /\.svg$/);
req.keys().map(req)
const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus, {size: 'default'})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component('svg-icon', SvgIcon);
app.config.globalProperties.$api = api
app.config.globalProperties.deepClone= deepClone
app.config.globalProperties.downFile= downFile
app.mount('#app');

