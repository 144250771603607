import {
    createRouter,
    createWebHashHistory
} from "vue-router";

const routes = [{
        path: '/',
        name: "Login",
        component: () => import("@/views/login/index.vue"),
        meta: {
            title: 'login'
        },
    },
    {
        path: '/CompanyCenter',
        name: "CompanyCenter",
        redirect:"/CompanyCenter/questionnaire",
        component: () => import("@/views/companyCenter/index.vue"),
        meta: {
            title: 'companyCenter'
        },
        children: [
        {
            path: '/CompanyCenter/questionnaire',
            name: "ComQuestionnaire",
            component: () => import("@/views/companyCenter/child/questionnaire/index.vue"),
        }, 
        {
            path: '/CompanyCenter/makeQuest',
            name: "ComMakeQuest",
            component: () => import("@/views/companyCenter/child/makeQuest/index.vue"),
        }, 
        {
            path: '/CompanyCenter/collect',
            name: "ComCollect",
            component: () => import("@/views/companyCenter/child/collect/index.vue"),
        }, 
        {
            path: '/CompanyCenter/personalReport',
            name: "ComPersonalReport",
            component: () => import("@/views/companyCenter/child/personalReport/index.vue"),
        },
        {
            path: '/answer',
            name: "Answer",
            component: () => import("@/views/answer/index.vue"),
        }
    ]
    },
    {
        path: '/Layout',
        name: "Layout",
        component: () => import("@/components/Layout/index.vue"),
        meta: {
            title: 'Layout'
        },
        children: [{
                path: '/questionnaire',
                name: "Questionnaire",
                component: () => import("@/views/questionnaire/index.vue"),
            },
            {
                path: '/makeQuest',
                name: "MakeQuest",
                component: () => import("@/views/makeQuest/index.vue"),
            },
            {
                path: '/collect',
                name: "Collect",
                component: () => import("@/views/collect/index.vue"),
            },
            {
                path: '/personalReport',
                name: "PersonalReport",
                component: () => import("@/views/report/personalReport.vue"),
            },
            {
                path: '/enterpriseReport',
                name: "EnterpriseReport",
                component: () => import("@/views/report/enterpriseReport.vue"),
            },
            {
                path: '/itemBank',
                name: "ItemBank",
                component: () => import("@/views/itemBank/index.vue"),
            },
            {
                path: '/customerManagement',
                name: "CustomerManagement",
                component: () => import("@/views/customerManagement/index.vue"),
            },
            {
                path: '/customerManagement/companyDetails',
                name: "CompanyDetails",
                component: () => import("@/views/customerManagement/comp/companyDetails.vue"),
            },
          
        ]
    }
];
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
export default router;