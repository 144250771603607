import axios from "axios"
import store from '@/store'
import { ElMessage } from "element-plus";
function downFile(url,data,fileName){
    axios({
        url: process.env.VUE_APP_URL+url,
        method: "POST",
        headers: {
            'Content-Type':"application/json",
            'device-type':"web",
            "token":store.state.token || ""
        },
        responseType: 'blob',
        data
    }).then(res=>{
        let blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let objectUrl = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", fileName);
          a.click();
          ElMessage.info("文件下载中请稍后!")
          window.URL.revokeObjectURL(objectUrl);
    })

}
export default downFile