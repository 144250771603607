import LS from 'cz-storage'
export default {
    setToken: (state, log) => {
        state.token = log
        LS.remove('token');
        LS.put('token', log, 1 / 8);
    },
    setTags: (state, log) => {
        let arr = JSON.parse(JSON.stringify(state.tags));
        let n = arr.findIndex(ele => {
            return ele.path === log.path
        })
        if(n === -1) {
            arr.push(log)
        }
        LS.remove('tags');
        LS.put('tags', arr, 1 / 8);
    },
    setTagActive: (state, log) => {
        state.tagActive = log;
        LS.remove('tagActive');
        LS.put('tagActive', log, 1 / 8);
    },
    setBreadcrumbs: (state, log) => {
        state.breadcrumbs = log;
    },
    setKind: (state, log) => {
        LS.put('kind', log, 1 / 8);
        state.kind = log;
    }
}